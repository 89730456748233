@tailwind base;
@tailwind components;
@tailwind utilities;

.font-didot {
  font-family: "Didot LT Std", serif;
}
.font-montserrat {
  font-family: "Montserrat", sans-serif;
}
